@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";
@import "../../../../components/UIKit/Button/Button.module.scss";
@import "~bootstrap/scss/bootstrap-utilities";

.sectionOffers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #E5FBF8 0%, #ECFDF9 100%);
  padding: 40px 0;

  @include media-breakpoint-up(xs) {
    padding: 60px 0;
  }

  &__bottomButton {
    display: flex;
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    min-height: 48px !important;
  }
}

.cardOffer {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 20px;
  border: 1px solid $green-dark-3;
  padding: 40px 20px 36px;
  margin-bottom: 32px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    margin-bottom: 40px;
  }

  &__topContent {
    flex-grow: 1
  }

  &__bottomContent {
    padding-top: 25px;
  }

  &__icon {
    @include sizeElement(80px);
    @include inlineFlexCenter;

    border-radius: 50%;
    background-color: $white;
    box-shadow: 3px 4px 8px 0px rgba($black, 0.25);
    color: $green-dark-3;
    font-size: 42px;
    margin-bottom: 16px;
  }

  &__titleHolder {
    margin-bottom: 25px;
    text-align: center;

    @include media-breakpoint-up(sm) {
      margin-bottom: 10px;
    min-height: 96px;
    }
  }

  &__title {
    font-size: 24px !important;
    margin-bottom: 10px;
  }

  &__descriptionList {
    color: $gray-700;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    li {
      display: inline-block;
      padding: 0 8px;

      &:not(:last-child) {
        border-right: 1px solid currentColor;
      }
    }
  }

  &__narrowСontent {
    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }
  }

  &__infoList {
    font-size: 14px;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    > div {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 24 24' viewBox='0 0 24 24'><path d='M12.5 0c6.6 0 12 5.4 12 12s-5.4 12-12 12-12-5.4-12-12 5.4-12 12-12z' shape-rendering='crispEdges' style='fill:#{$primary}' transform='translate(-.5)'/><path d='m7.9 11.7 3.5 3.5 5.7-5.7' style='fill:none;stroke:#fffefb;stroke-width:2;stroke-linecap:round;stroke-linejoin:round' transform='translate(-.5)'/></svg>"));
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: left top 0;
      min-height: 24px;
      padding: 2px 0 2px 36px;

      @include media-breakpoint-up(lg) {
        background-position: left top 2px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &__price {
    font-size: 40px !important;
    color: $green-dark-3;
    text-align: center;
    font-weight: 600;
    margin-bottom: 24px;
  }
}

.cardOffer_headline_container{
  background-color:  $green-dark-3;
    border-radius: 8px;
    font-size: 24px;
    color: white;
  line-height: 1;
    text-wrap: nowrap;
    margin-bottom: 27px;
  padding: 12px 38px;
  @media screen and (min-width: 1228px) and (max-width: 1600px) {
    padding: 12px 10px;
  }

  @media screen and (min-width: 768px) and (max-width: 1286px) {
    padding: 12px 5px;
    font-size: 22px;
  }
  @media screen and (max-width: 768px) {
    padding: 12px 10px;
  }

}

.cardOffer_headline{
  text-wrap: nowrap;
  
  @media screen and (max-width: 768px) {
    font-size: 5.5vw;
  }
}
.checkboxLikeButton {
  position: relative;
  display: block;

  &__input {
    @include visuallyHidden;

    &:checked {
      + .checkboxLikeButton__block {
        .checkboxLikeButton__notSelectedYetBlock {
          display: none;
        }

        .checkboxLikeButton__selectedBlock {
          display: block;
        }
      }
    }

    &:disabled {
      + .checkboxLikeButton__block {
        .checkboxLikeButton__notSelectedYetBlock {
          background-color: $gray-150;
          border-color: $gray-150;
          cursor: not-allowed;
        }
      }
    }

    &:checked:disabled {
      + .checkboxLikeButton__block {
        pointer-events: none;

        .checkboxLikeButton__notSelectedYetBlock {
          display: none;
        }

        .checkboxLikeButton__selectedBlock {
          display: none;
        }

        .checkboxLikeButton__selectedByDefaultBlock {
          display: inline-flex;
        }
      }
    }
  }

  &__block {
    display: block;
    cursor: pointer;
  }

  &__notSelectedYetBlock {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-sm;

    width: 100%;
  }

  &__selectedBlock {
    position: relative;
    padding-right: calc($btn-sm-height + 8px);
    display: none;

    &:hover {
      .checkboxLikeButton__selectedBlockUncheck {
        background-color: $white;
        border-color: darken($pink, 12.5%);
        color: $red;
      }
    }
  }

  &__selectedBlockText {
    @extend .btn;
    @extend .btn-outline-green-light;
    @extend .btn-sm;

    width: 100%;
    pointer-events: none;
  }

  &__selectedBlockUncheck {
    @include sizeElement($btn-sm-height);
    @extend .btn;
    @extend .btn-outline-red-light;
    @extend .btn-sm;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    min-width: auto;
    padding: 5px;
  }

  &__selectedByDefaultBlock {
    @extend .btn;
    @extend .btn-green-light;
    @extend .btn-sm;

    width: 100%;
    display: none;
  }
}